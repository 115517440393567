@import 'tools/media-query';
@import 'settings/variables';

:root {
    --font-weight-header: 500;
    --font-family-header: Roboto, Arial, Helvetica, sans-serif;
    --font-family-hero: RobotoCondensed, Arial, Helvetica, sans-serif;
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;

    src: url('https://img.ui-portal.de/ci/gmx/global/fonts/roboto/Roboto-Regular-webfont.woff') format('woff');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('https://img.ui-portal.de/ci/gmx/global/fonts/roboto/Roboto-Medium-webfont.woff') format('woff');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://img.ui-portal.de/ci/gmx/global/fonts/roboto/RobotoCondensed-Regular-webfont.woff') format('woff');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('https://img.ui-portal.de/ci/gmx/global/fonts/roboto/RobotoCondensed-Light-webfont.woff') format('woff');
}

.pos-brand-icon--default {
    width: 68px;
    height: 22px;
}

.pos-brand-icon--small {
    width: 62px;
    height: 20px;
}

.pos-brand-title {
    margin-left: 11px;
    font-family: RobotoCondensed;
    font-weight: 300;
    font-size: 28px;
}

.pos-brand-title--small {
    margin-left: 10px;
    font-size: 26px;
}

.pos-header .pos-brand-logo {
    margin-top: -4px;
}
